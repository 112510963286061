@media all and (max-width: 1913px)  {
	.FooterLogo{
		width: 20%;
		display: block;
		float: left;
	}
	.FooterLogo{
		text-align: center;
	}
	.FooterText{
		display: block;
		float: left;
		width: 80%;
	}
	.FooterAddressText .dib{
		display: block;
		.divider{
			display: none;
		}
	}
	.dib.ml20{
		margin-left: 0px !important;
	}
}
@media all and (max-width: 1530px)  {
	.quoteBox{
		display: none !important;
	}
}
@media all and (max-width: 1480px)  {
	.QuickLink .QuickLinkCenter .part-2 {
	    font-size: 1.8em !important;
	}
}
@media all and (max-width: 1460px)  {
	#Logo .LogoText span {
	    font-size: 20px;
	}
	.LogoImage img{
	    width: 60px;
	}
	#Logo {
    	left: 10px;
    }
    .LogoText{
	    font-size: 0.65em;
	}

    #logo2{
    	display: none !important;
    }
    #MenuContainer {
    	right: 0px;
    }
    #SearchToggle {
	    right: 20px;
	}
	#UsefullLinks {
    	right: 93px;
	}
}
@media all and (min-width: 1202px)  {/*
@media all and (min-width: 1460px)  {*/
	#MobileNavigation{
		display: none !important;
	}
	#MenuIcon{
		display: none !important;
	}
}
@media all and (max-width: 1202px)  {
/*@media all and (max-width: 1460px)  {*/
	/*#Logo .LogoText span {
	    font-size: 20px;
	}*/
	#MenuContainer{
		display: none;
	}
	#MenuIcon{
		display: block;
	}

	.topBar {
	    min-height: 80px;
    }
    #UsefullLinks {
    	padding: 5px 10px 10px 10px;
    }
    /*#StaffArea {
    	top: 80px;
    	padding: 15px 40px 10px 10px;
    	right: 71px;
    }*/
    #SearchToggle {
    	top: 80px;
    	right: 18px;
    	img{
    		height: 22px;
    	}
    }
    #UsefullLinks {
    	top: 80px;
        right: 202px;
    }

/***/
#StaffArea,
    #UsefullLinks {
    	padding: 5px 10px 10px 10px;
    }
    #StaffArea {
    	top: 80px;
    	right: 71px;
    }
    #SearchToggle {
    	top: 80px;
    	right: 18px;
    	img{
    		height: 22px;
    	}
    }

    #StaffArea,
    #UsefullLinks {
    	top: 80px;
        right: 228px;
    }
    #StaffArea {
    	top: 80px;
    	right: 64px;
    	padding-top: 5px;
    }
/***/

    .topBar {
    	border-bottom: none !important;
    } 

    .SearchContainer {
    	top: 95px;
	    right: 19px;
	}

	.SearchContainer:before {
    	right: 11px;
    }

	/*.LogoImage img{
	    width: 60px;
	}
	#Logo {
    	left: 10px;
    }
    .LogoText{
	    font-size: 0.65em;
	}

    #logo2{
    	display: none !important;
    }*/
}
@media all and (max-width: 1200px)  {

	#home3{
		display: block;
		overflow: hidden !important;
	}
	#newsLeft{
		display: none !important;
	}
	#newsRight{
		width: 100%;
		clear: both;
	}
	.FooterAddress{
		width: 100%;
		clear: both;
		text-align: center;
	}
	.FooterText{
		padding-left: 0px !important;
	}
	footer .FooterLogo,
	.FooterText,
	.FooterAddress,
	footer{
		width: 100%;
		clear: both;
	}
	.totop img{
		display: inline-block;
		float: none;
	}
	.totop {
	    margin-top: 20px;
	}
	.fse{
		text-align: center;
	}

	footer {
	    padding: 40px 20px 40px 20px;
	}

	footer .col-4.tar{
		width: 100%;
		text-align: center;
	}
}
@media all and (max-width: 1120px)  {
	.QuickLink {
		width: 50% !important;
		padding-bottom: 50% !important;
	}
}
@media all and (max-width: 991px)  {
	.hidden-sm {
		display: none !important;
	}
}


@media all and (max-width: 1050px)  {

.rightImage,
.leftImg{
	top: 0px;
	position: absolute;
	height: 100%;
	width: 50%;
}
.rightImage{
	right: 0px;
}
.leftImg{
	left: 0px;
}

	
	.homeImg {
		margin-bottom: 30px !important;
	}
	#home1 svg {
		position: absolute;
	    bottom: -52px;
	    @include transform(translateX(-50%));
	    left: 50%;
	}
	#home1 p {
    	font-size: 17px;
    }

	h1.pageTitle +svg{
	    left: 20px;
	}

	#home3 .pageTitle +svg {
		left: 50%;
		position: absolute;
		@include transform(translateX(-50%));    
		bottom: 13px;
	}

	*{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
	.clearMid{
		clear: both;
		width: 100% !important;
	}
	#home3 .pageTitle{
    padding-bottom: 40px;
		text-align: center;
	}
	#home3 .pageTitle img.line,
	#home1 .pageTitle img.line{
		left: 50%;
		@include transform(translateX(-50%));
	}

	.flex{
		display: block;
	}

	.SlideInner{
		display: flex !important;
	}


	
	.tacMid{
		text-align: center !important;
	}
	.contentBorder{
		border: 0px !important;
	}
	.contentPage{
		padding-left: 20px !important;
	}

	.contentBorder .col-12.pl10.pr10{
		padding-left: 20px;
		padding-right: 20px;
	}
	#sidebar{
		padding-left: 20px !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}
}


@media all and (max-width: 1030px)  {
	#sideLinks a .title{
	
	}
	#sideLinks a {
	    padding: 5px 30px 5px 20px;
	}

	#sideLinks a:hover {
	    padding: 5px 40px 5px 20px;
	}
}
@media all and (max-width: 810px)  {
	#sideLinks{
		display: none !important;
	}
}
@media all and (max-width: 968px)  {
	

	.pageoverlay{
		right: 10%;
		max-width: 70% !important;
	}

	.overlay,
	.newsOverlay,
	.overlay3{
		max-width: 70% !important;
	}
}
@media all and (max-width: 900px)  {
	#MobileNavigation {
	    width: 100%;
	    right: -100%;
	}
	#MobileNavigation .TopLevel{
   		max-width: 380px;
	}

	.pageImage, .homeImg {
	    margin: 0 auto;
	}
}

@media all and (max-width: 780px)  {
	.pageTitle {
    	font-size: 2em;
    }

    .SlideInner{
		display: block !important;
	}
    .SlideInner{
		.col-8,
		.col-4{
			width: 100%;
			clear: both;
		}
		.col-4{
			padding-bottom: 50%;
		}
	}
	#LatestEvents{
		.col-4{
			font-size: 0.8em;
		}
	}

}

@media all and (max-height: 866px)  {
	.banner {
	    height: 100vh;
	}

	.flexslider .slides li {
		    height: 100vh;
	}
	.BannerText {
	    position: absolute;
	    bottom: 179px !important;
		margin-bottom: 0px !important;
	    opacity: 1 !important;
	}
}
@media all and (max-width: 700px)  {
	.EventsListContent,
	.EventsListImage{
		width: 100%;
		clear: both;
	}
	.eventsListItem{
		display: block;
	}
	.EventsListImage{
		padding-bottom: 40%;
	}
/*	.BannerText {
	    position: absolute;
	    bottom: 253px !important;
		margin-bottom: 0px !important;
	    opacity: 1 !important;
	}*/
	.BannerText span:last-of-type {
    	font-size: 1.2em !important;
	}
	.BannerText span:first-of-type {
    	font-size: 1.2em !important;
	}

}
@media all and (max-width: 670px)  {
	.RepositoryUpload{
		display: block;
		float: left;
		width: 100%;
		clear: both;
	}

	#LatestEvents{
		.col-4{
			width: 100%;
			clear: both;
		    max-width: 300px;	
		    margin: 0 auto;
		    float: none;
		}
	}

	#newsRight {
	    padding: 150px 0px !important;
	    section{
	    	.col-12.clear.pt10.pb10.pr20{
	    		padding-left: 20px !important;
	    		padding-right: 30px !important;
	    	}
	    }
	}
	#SliderControls{
		margin-left: 20px;
	}
}

@media all and (max-height: 455px)  {
	#down{
		display: none !important;
	}
	.BannerText {
   		bottom: 59px !important;
	}
}
@media all and (max-width: 500px)  {
	.BannerText {
	    font-size: 1em !important;
	}
	.QuickLink {
		width: 100% !important;
		padding-bottom: 100% !important;
	}
	#UsefullLinks {
	    top: 80px;
	    right: auto;
	    left: 10px;
	}
	#SearchToggle {
	    right: 10px;
	}
	#StaffArea {
	    right: 54px;
	}
	.SearchContainer {
	    right: 10px;
	}
	#StaffArea,
	#LinksToggle {
	    font-size: 0.8em !important;
	}

	#SearchToggle img {    
		height: 19px;	}

	.calendar-widget-table {
		font-size: 0.8em !important;
	}
	.calendar-widget-table .next,
	.calendar-widget-table .prev {
		    font-size: 1.7em !important;
	}
}
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 30px 0 0;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}

@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 460px)  {




	#StaffArea {
		img{
	        right: 5px;
    		bottom: 4px;
	    }
	}
	.pageTitle {
	    font-size: 1.7em;
	}
	.pageTitle img.line {
	    bottom: -16px;
	    width: 50px;
	}
	
	.LogoText span:first-of-type{
	    width: 200px;
	    line-height: 1.1em;
	}

	.LogoText span:last-of-type{
		display: none !important;
	}

	.tacSmall{
		text-align: center !important;
	}
	.hiddenSmall {
		display: none !important;
	}
}
@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
