/**
 * This typography file is included in the WYSIWYG editor
 */
.typography * {
}
.typography blockquote {
	margin-left:20px;
}
.typography hr {
	padding:5px;
}
p,
ul li,
ol li,
a {
	
	line-height:1.5em;
	font-size:1.1em;
}
p {
	margin-bottom:20px;
}


/** CMS LIST STYLES **/
.typography ul,
.typography ol {
	margin-left:10px;
	margin-bottom:20px;
}
	.typography ul li,
	.typography ol li {
		margin:4px 10px;
		line-height:1.4em;
	}
		.typography ul li {
		 list-style-type:disc;
		}
		.typography ol li {
			list-style-type:decimal;
    }
/* HEADER STYLES */

h1,
h2,
h3,
h4 {
	margin-bottom:14px;
	color: #135f91;
	*{color: #135f91;}
}
h1,
h4 {
	font-weight:bold;
}
h2,
h3 {
	font-weight:normal;
}
	h1 {
		font-size:2.2em;
	}
	h2 {
		font-size:2.2em;
		line-height: 1em;
	}
	h3 {
		font-size:1.6em;
	}
	h4 {
		font-size:1.4em;
	}

/* LINK STYLES */
.typography a {
	color: $LinkColor;
  	font-size:1em;
  	text-decoration:none;
}
	.typography a:hover {
		text-decoration:underline;
	}
	
	
.typography table {
	font-size:1.1em;
}
	.typography table tr td {
		padding:3px 5px;
	}

	
/* WYSIWYG EDITOR ALIGNMENT CLASSES */
.typography .left {
	text-align:left;
}
.typography .center {
	text-align:center;
}
.typography img.center {
	display: inline-block;	
}
.typography .right {
	text-align:right;
}
.typography img.right {
	float:right;
}
.typography img.left {
	float:left;
}
.typography img {
	max-width: 100%;	
}
.typography p.MsoNormal, .typography p.MsoBodyText {
	margin: 0;
}
