/*** Mobile Screens ***/
#sidebar{
  width: 100%;
  padding-left: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
  float: right;
  position: relative;
  font-size: 1em;
  *{font-size: 1em;}
  .Level-1{
    display: none;
        padding-left: 20px !important;
    ul{
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.7em !important;
    color: #525251;
    &:hover{
      color: #2f98f0;
    }
  }
  .sidebar-heading{
    display: block;
    float: left;
    clear: both;
    width: 80%;
    a{
      padding: 5px 30px 5px 40px;
      display: block;
    
      text-decoration: none;
      font-family: 'BellotaBold';
      color: #1f0b79;
        font-size: 1.8em;
        position: relative;
        margin-bottom: 40px;
        img.line{
          position: absolute;
          bottom: -10px;
          left: 40px;
          width: 55px !important;
        }

    }
  }
  li{
    list-style: none !important;
  }
  ul{

    ul{
      display: none;

       padding-left: 20px !important;

      li{
        a{
          /*** Level Indentation ***/
          display: block;
          position: relative;
          &:before{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0px;
            height: 10px;
            width: 10px;
            @include border-radius(50%);
            background-color: #2f98f0;
            @include transform(translateY(-50%));
          }
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
           
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;

      a{
        display: block;
        padding: 5px 30px 5px 10px;
        padding-left: 20px !important;
        text-decoration: none;
        @include transition($t1);
        background-color: rgba(0,0,0,0.0);
        position: relative;

        img.line{
          top: 50%;
          @include transform(translateY(-50%));
          position: absolute;
             left: -16px;

          width: 30px !important;
        }

        span{
          color: #525251;
          @include transition($t1);
        }
        &:hover span{
          color: #2f98f0 !important;
        }
        
      }
       a.current span{
          color: #1f0b79 !important;
       }
    }
  }
}
.currentChild{
  span{
          color: #1f0b79 !important;
       }
}
.currentChild{
  &:before{
    display: none !important;
  }
}
.current + button{
  display: none !important;
}
.current + button + [class*=Level-]{
  display: block !important;
}


.hideSideBarToggle{
    top: 12px;
}
#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (max-width: 1050px) {
  #sidebar{
    ul,li{
      margin: 0px !important;
      }
      padding-left: 0px !important;
      background-color: #eee;
      margin-top: 60px !important;

    .sidebar-heading a {
        color: #135f91;
      margin-bottom: 0px !important;
    }
    .sidebar-heading .line{
      display: none !important;
    }
  }

  #sidebar .hideSideBarToggle {
    top: 6px;
    right: 20px;
  }

  
}
@media all and (min-width: 1051px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
